<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Mijn account</h1>

      <ValidationObserver ref="form1" slim>
        <b-card class="mb-4">
          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="required">Voornaam</label>
                  <b-form-input v-model="form.firstname" />
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </b-col>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="required">Achternaam</label>
                  <b-form-input v-model="form.lastname" />
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">E-mailadres</label>
              <b-form-input v-model="form.username" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Telefoonnummer</label>
              <b-form-input v-model="form.phone"/>
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </b-card>

        <div class="clearfix">
          <b-button @click="saveAccount" variant="primary" class="float-right">
            Opslaan
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </b-button>
        </div>
      </ValidationObserver>
      
      <ValidationObserver ref="form2" slim>
        <h1 class="fs-4 font-weight-bold mb-4">Wachtwoord wijzigen</h1>
        <b-card class="mb-4">
          <ValidationProvider vid="password" rules="required|min:8" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Wachtwoord</label>
              <b-form-input v-model="form.password" type="password" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Wachtwoord bevestigen</label>
              <b-form-input v-model="form.password_repeat" type="password" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </b-card>
      </ValidationObserver>

      <div class="clearfix">
        <b-button @click="savePassword" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>
      
    </b-container>
  </div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';

  export default {
      metaInfo: {
          title: 'Mijn account',
      },
      components: {
        ValidationObserver,
        ValidationProvider,
      },
      data() {
          return {
            form: {
              firstname: null,
              lastname: null,
              username: null,
              phone: null,
              password: null,
              password_repeat: null,
            }
          }
      },
      computed: {},
      methods: {
        async saveAccount() {

          let check = await this.$refs['form1'].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            return;
          }

          this.$axios.put("https://api.tixgo.nl/profiles/update", this.form)
            .then( response => {
              response;
              this.$router.push('/manage')
            }
          );
        },
        async savePassword() {

          let check = await this.$refs['form2'].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            return;
          }

          this.$axios.put("https://api.tixgo.nl/profiles/update", this.form)
            .then( response => {
              response;
              this.$router.push('/manage')
            }
          );
        },
        getAccount: function() {
          this.$axios.get("https://api.tixgo.nl/profiles/show")
            .then( response => {
              this.form = response.data;
          }).catch(response => { console.log(response); });
        }
      },
      created() {
        this.getAccount();
      }
  }
</script>